/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-23",
    versionChannel: "nightly",
    buildDate: "2025-01-23T00:06:09.609Z",
    commitHash: "85d92d3cbebc27038bbb20db2f541538200b31e1",
};
